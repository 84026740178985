<template>
  <div>
    <div class="row align-items-stratch">
      <div class="col-3 my-3">
        <!-- <label for="">Image</label> -->
        <button class="w-100 h-100 btn border border-success">..</button>
        <input type="file" id="photo" hidden class="form-control" />
      </div>

      <div class="col-9 row align-items-end">
        <div class="col-4 my-3">
          <label for="">Catégorie</label>
          <select class="form-select" v-model="item.section_reference">
            <option
              v-for="(section, index) in sections"
              :key="index++"
              :value="section.reference"
            >
              {{ section.fullName }}
            </option>
          </select>
        </div>

        <div class="col-6 my-3">
          <label for="">Désignation</label>
          <input type="text" v-model="item.fullName" class="form-control" />
        </div>

        <div class="col-2 my-3">
          <label for="">Unité </label>
          <select class="form-select" v-model="item.measuring">
            <option value="KG">KG</option>
            <option value="Pc">Pièce</option>
            <option value="L">Litre</option>
            <option value="M">Mètre</option>
          </select>
        </div>

        <div class="col-12 my-3">
          <label for="">Description</label>
          <textarea v-model="item.description" class="form-control"></textarea>
        </div>
      </div>
    </div>

    <br />
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(item)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      item: {},
    };
  },
  computed: {
    ...mapGetters("section", {
      sections: "getAll",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("item/store", data);
      await setInterval((this.item = {}), 1000);
    },
    async empty() {
      this.$store.commit("item/setItem", {});
    },
  },
  beforeMount() {
    this.$store.dispatch("section/getAll");
  },
};
</script>
